import React, { useState, useEffect } from "react";
import http from '../_api/http';
const CampusInfra = () => {
    const [loading, setLoading] = useState(true);
    const [pdf, setpdf] = useState([]);
    const PUBLIC_URL = process.env.REACT_APP_APIURL;

    useEffect(() => {
        http.get('api/pdf/ApiPdfList?pdf_for_type=22').then((res) => {
            if (res.data.status === 200) {
                setpdf(res.data.pdf);
                setLoading(false);
            }
        });
    }, []);
    return (
        <section className="gallery-one">
            <div className="container">
                <div className="row">
                    {loading
                        ? <h4>View Canteen Menu Loading...</h4>
                        :
                        <>
                            {pdf.map((item, i) =>
                                <div className="col-lg-3 col-md-4" key={i} style={{textAlign:"center"}}>
                                    <a href={process.env.REACT_APP_APIURL + 'files/' + item.name} target="_blank" className="col-12 pdfBlock" >
                                        <i class="fa fa-file-pdf pdfIcon"></i>
                                        <label className="col-12 pdfLabel">
                                            {item.pdf_select_for}
                                        </label>
                                    </a>
                                </div>
                            )}

                        </>
                    }
                </div>
            </div>
        </section>
    );
};

export default CampusInfra;
